/* Kulüp Detay Sayfası için Özel Stiller */

:root {
  --tennis-ball-green: #ccff00;
  --tennis-ball-light-green: #d8e96c;
  --tennis-ball-dark-green: #94a82e;
  --white: #ffffff;
  --black: #000000;
  --light-gray: #f5f5f5;
  --dark-gray: #333333;
}

/* Header Stilleri */
.club-detail-header {
  background-color: var(--tennis-ball-green) !important;
  /* border-bottom: 1px solid var(--white); */
}

.club-detail-header.fix_style {
  background-color: rgba(204, 255, 0, 0.95) !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.club-detail-header .navbar-expand-lg .navbar-nav .nav-link {
  color: #000;
  transition: color 0.3s ease;
  font-size: 15px;
  font-weight: 500;
}

.club-detail-header .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #000;
}

.club-detail-header .navbar-expand-lg .navbar-nav .nav-item.active .nav-link {
  color: var(--black);
}

/* Logo Stilleri */
.club-detail-header .navbar-brand {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
}

.club-detail-header .navbar-brand .pfm-logo {
  height: auto;
  width: auto;
  filter: brightness(0) invert(0); /* Logo rengini değiştir (siyah yapacak) */
}

/* Kulüp Logosu ve Adı Container */
.club-detail-header .navbar-brand .club-brand-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 15px;
}

.club-detail-header .navbar-brand .club-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--black);
  max-width: 170px;
  transition: color 0.3s ease;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 15px;
}

.club-detail-header .navbar-brand .club-brand-container:hover .club-name {
  color: #333;
}

.club-detail-header .navbar-brand .club-logo {
  height: auto;
  max-height: 60px;
  width: auto;
  max-width: 80px;
  transition: transform 0.3s ease;
}

.club-detail-header .navbar-brand .club-brand-container:hover .club-logo {
  transform: scale(1.05);
}

/* Mobile Responsive için Kulüp Adı Stilleri */
@media (max-width: 768px) {
  .club-detail-header .navbar-brand .club-name {
    font-size: 16px;
    max-width: 150px;
  }

  .club-detail-header .navbar-brand .club-logo {
    max-height: 50px;
    max-width: 70px;
  }
}

@media (max-width: 480px) {
  .club-detail-header .navbar-brand .club-name {
    font-size: 14px;
    max-width: 120px;
  }

  .club-detail-header .navbar-brand .club-logo {
    max-height: 45px;
    max-width: 60px;
  }
}

.club-detail-footer .logo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.club-detail-footer .logo .pfm-logo {
  height: auto;
  width: auto;
  filter: brightness(0) invert(0); /* Logo rengini değiştir (siyah yapacak) */
}

.club-detail-footer .logo .club-logo {
  height: auto;
  max-height: 60px;
  width: auto;
  margin-left: 15px;
  border-left: 1px solid var(--black);
  padding-left: 15px;
}

.club-detail-header .toggle-bar,
.club-detail-header .toggle-bar::before,
.club-detail-header .toggle-bar::after {
  background-color: var(--black);
}

.club-detail-header .toggle-wrap.active .toggle-bar::before,
.club-detail-header .toggle-wrap.active .toggle-bar::after {
  background-color: var(--black);
}

.club-detail-header .navbar-expand-lg .navbar-nav .club-panel-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: #000;
  border-radius: 4px;
  padding: 8px 15px;
  transition: background-color 0.3s ease;
}

.club-detail-header .navbar-expand-lg .navbar-nav .club-panel-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

/* Footer Stilleri */
.club-detail-footer {
  background-color: var(--tennis-ball-green) !important;
  color: var(--black) !important;
  /* padding: 60px 0 0 0 */
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin-bottom: 40px;
}

.footer-section {
  margin-bottom: 30px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-logo .pfm-logo {
  height: auto;
  max-height: 55px;
  width: auto;
  filter: brightness(0) invert(0);
}

.footer-logo .club-logo {
  height: auto;
  max-height: 55px;
  width: auto;
  border-left: 1px solid var(--black);
  padding-left: 20px;
}

.footer-description {
  color: var(--black);
  font-size: 14px;
  line-height: 1.6;
  opacity: 0.9;
}

.footer-title {
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  text-align: left;
}

.footer-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--black);
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: var(--black);
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
}

.footer-links a:hover {
  color: var(--white);
  padding-left: 5px;
}

.footer-contact {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-contact li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

.footer-contact i {
  font-size: 18px;
}

.footer-contact a {
  color: var(--black);
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-contact a:hover {
  color: var(--white);
}

.social-links {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--white);
  color: var(--black);
  border-radius: 50%;
  transition: all 0.3s ease;
  font-size: 20px;
}

.social-links a:hover {
  background-color: var(--black);
  color: var(--white);
  transform: translateY(-3px);
}

.footer-bottom {
  background-color: var(--black);
  /* padding: 20px 0; */
  /* margin-top: 40px; */
  width: 100%;
  border-radius: 10px;
}

.footer-bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 20px; */
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 2rem;
  background-color: rgba(0, 0, 0, 0.2);
}

.copyright {
  width: 100%;
  text-align: center;
}

.copyright p {
  color: var(--white);
  font-size: 12px;
  margin: 0;
  opacity: 0.8;
}

.legal-links {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.legal-links a {
  color: var(--white);
  text-decoration: none;
  font-size: 12px;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.legal-links a:hover {
  opacity: 1;
}

@media (min-width: 768px) {
  .copyright {
    width: auto;
    text-align: left;
  }

  .legal-links {
    width: auto;
    margin-top: 0;
  }
}

/* Laptop ve Büyük Ekranlar (1200px ve üzeri) */
@media (min-width: 1200px) {
  .footer-content {
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
  }

  .footer-section {
    margin-bottom: 40px;
  }

  .footer-logo {
    gap: 25px;
  }

  .footer-logo .pfm-logo {
    max-height: 60px;
  }

  .footer-logo .club-logo {
    max-height: 60px;
  }
}

/* Tablet ve Küçük Laptop (992px - 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  .footer-section {
    margin-bottom: 35px;
  }

  .footer-logo {
    gap: 20px;
  }

  .footer-logo .pfm-logo {
    max-height: 55px;
  }

  .footer-logo .club-logo {
    max-height: 55px;
  }
}

/* Tablet (768px - 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .club-detail-footer {
    padding: 50px 0 0;
  }

  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
  }

  .footer-section {
    margin-bottom: 30px;
  }

  .footer-logo {
    gap: 15px;
  }

  .footer-logo .pfm-logo {
    max-height: 50px;
  }

  .footer-logo .club-logo {
    max-height: 50px;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-bottom-content {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .legal-links {
    justify-content: center;
  }
}

/* Mobil (576px - 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .club-detail-footer {
    padding: 40px 0 0;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .footer-section {
    margin-bottom: 25px;
    text-align: center;
  }

  .footer-logo {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .footer-logo .pfm-logo {
    max-height: 50px;
  }

  .footer-logo .club-logo {
    max-height: 50px;
    border-left: 1px solid var(--black);
    padding-left: 20px;
    border-top: none;
    padding-top: 0;
  }

  .footer-title {
    font-size: 16px;
    text-align: center;
  }

  .footer-title::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-links {
    justify-content: center;
  }

  .footer-bottom-content {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .legal-links {
    justify-content: center;
    flex-wrap: wrap;
  }
}

/* Küçük Mobil (575px ve altı) */
@media (max-width: 575px) {
  .club-detail-footer {
    padding: 30px 0 0;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 25px;
  }

  .footer-section {
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-logo {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .footer-logo .pfm-logo {
    max-height: 45px;
  }

  .footer-logo .club-logo {
    max-height: 45px;
    border-left: 1px solid var(--black);
    padding-left: 15px;
    border-top: none;
    padding-top: 0;
  }

  .footer-title {
    font-size: 15px;
    text-align: center;
  }

  .footer-title::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-links {
    justify-content: center;
  }

  .footer-bottom {
    padding: 15px 0;
    margin-top: 30px;
  }

  .footer-bottom-content {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .copyright p {
    font-size: 11px;
  }

  .legal-links {
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .legal-links a {
    font-size: 11px;
  }
}

/* Language Switcher Özel Stilleri */
.club-detail-language .lang-toggle {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--black);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.club-detail-language .lang-toggle:hover,
.club-detail-language .lang-toggle:focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--black);
}

.club-detail-language .flag-icon {
  margin-right: 8px;
  width: 20px;
  height: auto;
}

.club-detail-language .lang-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1100;
  min-width: 120px;
  background-color: var(--tennis-ball-green);
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px;
}

.club-detail-language .lang-option {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  color: var(--black);
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.club-detail-language .lang-option:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--black);
}

.club-detail-language .lang-option.active {
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--black);
}

.club-detail-language .lang-text {
  margin-left: 8px;
  font-weight: 500;
}

/* Mobil Ekranlar için Language Switcher */
@media (max-width: 768px) {
  .club-detail-language {
    margin: 10px 0 5px;
    width: 100%;
  }

  .club-detail-language .lang-toggle {
    width: 100%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px 15px;
  }

  .club-detail-language .lang-dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 5px;
    background-color: var(--tennis-ball-green);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .club-detail-language .lang-option {
    margin: 2px 0;
  }
}

@media (max-width: 480px) {
  .club-detail-language {
    margin: 5px 0;
  }

  .club-detail-language .lang-toggle {
    padding: 6px 10px;
  }
}

/* Club Hero Styles */
.club-hero {
  position: relative;
  min-height: 60vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.club-hero__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.club-hero__background-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transform: scale(1.05);
  transition: transform 8s ease;
}

.club-hero:hover .club-hero__background-image {
  transform: scale(1.1);
}

.club-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2rem;
  z-index: 5;
  position: relative;
}

.club-hero__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8rem 0;
  min-height: 60vh;
}

.club-hero__logo {
  background: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  margin-bottom: 2rem;
}

.club-hero__logo-image {
  width: 100%;
  height: auto;
  max-width: 280px;
  display: block;
  object-fit: contain;
}

.club-hero__info {
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.club-hero__title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1.75rem;
  line-height: 1.1;
  letter-spacing: -0.02em;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  width: 100%;
  margin: 0 0 2rem 0;
  padding: 0;
  white-space: nowrap;
  display: block;
}

.club-hero__description {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2.5rem;
  opacity: 0.95;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  max-width: 800px;
}

.club-hero__description p {
  margin-bottom: 1rem;
}

.club-hero__location {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.2rem;
  margin: 0 0 2.5rem 0;
  color: #fff;
  opacity: 0.9;
}

.club-hero__location-icon {
  font-size: 1.5rem;
  color: #ff4c4c;
}

.club-hero__contact {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.25rem;
  margin-top: 1rem;
  width: 100%;
}

.club-hero__contact-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #fff;
  text-decoration: none;
  padding: 1rem 1.25rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.club-hero__contact-item:hover {
  background: var(--tennis-ball-green);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-color: var(--tennis-ball-green);
  color: var(--black);
}

.club-hero__contact-icon {
  font-size: 1.25rem;
}

/* Büyük Ekranlar için Özel Ayarlar */
@media (min-width: 1400px) {
  .club-hero {
    min-height: 70vh;
  }

  .club-hero__content {
    padding: 9rem 0;
    min-height: 70vh;
  }

  .club-hero__title {
    font-size: 5rem;
  }
}

/* Laptop ve Orta Boy Ekranlar */
@media (max-width: 1200px) {
  .club-hero {
    min-height: 55vh;
  }

  .club-hero__content {
    padding: 7rem 0;
    min-height: 55vh;
  }

  .club-hero__title {
    font-size: 3.5rem;
  }
}

/* Tablet ve Küçük Laptop */
@media (max-width: 992px) {
  .club-hero {
    min-height: 50vh;
  }

  .club-hero__content {
    padding: 6rem 0;
    min-height: 50vh;
    align-items: center;
  }

  .club-hero__info {
    align-items: center;
    text-align: center;
  }

  .club-hero__title {
    font-size: 3rem;
    text-align: center;
    white-space: normal;
  }

  .club-hero__location {
    justify-content: center;
  }

  .club-hero__contact {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablet */
@media (max-width: 768px) {
  .club-hero {
    min-height: 100vh;
  }

  .club-hero__content {
    padding: 5rem 0;
    min-height: calc(100vh - 80px);
  }

  .club-hero__title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .club-hero__location {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .club-hero__contact {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .container {
    padding: 0 1.5rem;
  }
}

/* Mobil */
@media (max-width: 576px) {
  .club-hero {
    min-height: 100vh;
  }

  .club-hero__content {
    padding: 4rem 0;
  }

  .club-hero__title {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }

  .club-hero__location {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .club-hero__contact-item {
    padding: 0.75rem 1rem;
  }

  .container {
    padding: 0 1rem;
  }
}

/* Küçük Mobil Cihazlar */
@media (max-width: 360px) {
  .club-hero__title {
    font-size: 1.75rem;
  }

  .club-hero__location {
    font-size: 0.9rem;
  }

  .club-hero__contact-item {
    padding: 0.65rem 0.85rem;
    font-size: 0.9rem;
  }

  .club-hero__contact-icon {
    font-size: 1.1rem;
  }
}

/* Club Gallery Styles */
.club-gallery {
  padding: 5rem 0;
  background-color: #f8f9fa;
}

.club-gallery__title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #333;
}

.club-gallery__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 0 1rem;
}

.club-gallery__item {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.club-gallery__item:hover {
  transform: translateY(-5px);
}

.club-gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.club-gallery__item:hover .club-gallery__image {
  transform: scale(1.1);
}

.club-gallery__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.club-gallery__item:hover .club-gallery__overlay {
  opacity: 1;
}

.club-gallery__zoom {
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
}

/* Modal Styles */
.club-gallery__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 1rem;
}

.club-gallery__modal-content {
  position: relative;
  max-width: 80vw;
  max-height: 80vh;
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.club-gallery__modal-close {
  position: absolute;
  top: -40px;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.75rem;
  transition: all 0.3s ease;
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.club-gallery__modal-close:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(90deg) scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.club-gallery__modal-close:active {
  transform: rotate(90deg) scale(0.95);
}

.club-gallery__modal-image-container {
  width: 100%;
  height: 100%;
  max-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.club-gallery__modal-image {
  max-width: 100%;
  max-height: 75vh;
  object-fit: contain;
  border-radius: 0.25rem;
}

/* Responsive Styles */
@media (min-width: 1200px) {
  .club-gallery__modal-content {
    max-width: 70vw;
    max-height: 70vh;
  }
}

@media (max-width: 768px) {
  .club-gallery {
    padding: 3rem 0;
  }

  .club-gallery__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .club-gallery__grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .club-gallery__modal-content {
    max-width: 95vw;
    max-height: 95vh;
  }

  .club-gallery__modal-close {
    top: -35px;
    width: 35px;
    height: 35px;
    font-size: 1.25rem;
    padding: 0.5rem;
  }
}

@media (max-width: 576px) {
  .club-gallery__title {
    font-size: 1.75rem;
  }

  .club-gallery__grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.75rem;
  }

  .club-gallery__modal-close {
    top: -30px;
    width: 30px;
    height: 30px;
    font-size: 1.1rem;
    padding: 0.4rem;
  }
}

/* Club Contact Styles */
.club-contact {
  padding: 5rem 0;
  background-color: var(--white);
}

.club-contact__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: flex-end;
}

.club-contact__title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--black);
  position: relative;
  padding-bottom: 1rem;
}

.club-contact__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: var(--tennis-ball-green);
  border-radius: 3px;
}

.club-contact__details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.club-contact__item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: rgba(191, 215, 58, 0.1);
  border-radius: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.club-contact__item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(191, 215, 58, 0.2);
  background: rgba(191, 215, 58, 0.15);
}

.club-contact__icon {
  color: var(--tennis-ball-green);
  font-size: 1.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.club-contact__text {
  flex-grow: 1;
}

.club-contact__text h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--black);
}

.club-contact__text p {
  margin-bottom: 0.5rem;
  color: var(--black);
}

.club-contact__link {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.club-contact__link:hover {
  color: #333;
  text-decoration: underline;
}

.club-contact__map {
  position: relative;
  height: 500px;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--tennis-ball-light-green);
}

/* Club Map Styles */
.club-map {
  padding: 4rem 0;
  background-color: var(--light-gray);
}

.club-map__content {
  display: grid;
  gap: 2rem;
}

.club-map__title {
  text-align: center;
}

.club-map__title h2 {
  font-size: 2rem;
  color: var(--tennis-ball-dark-green);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
  display: inline-block;
}

.club-map__title h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--tennis-ball-green);
  border-radius: 3px;
}

.club-map__address {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(191, 215, 58, 0.1);
  border: 1px solid rgba(191, 215, 58, 0.2);
}

.club-map__icon {
  color: var(--tennis-ball-dark-green);
  font-size: 1.5rem;
  margin-top: 0.25rem;
}

.club-map__address-content h3 {
  font-size: 1.25rem;
  color: var(--black);
  margin-bottom: 0.5rem;
}

.club-map__address-content p {
  color: var(--black);
  line-height: 1.5;
}

.club-map__directions {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--tennis-ball-green);
  color: var(--black);
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.club-map__directions:hover {
  background-color: var(--tennis-ball-dark-green);
  color: var(--white);
  transform: translateY(-2px);
}

.club-map__container {
  width: 100%;
  height: 450px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--tennis-ball-light-green);
}

@media (max-width: 992px) {
  .club-contact {
    padding: 3rem 0;
  }

  .club-contact__content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .club-contact__title {
    font-size: 1.75rem;
  }

  .club-map__container {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .club-contact {
    padding: 3rem 0;
  }

  .club-contact__title {
    font-size: 2rem;
  }

  .club-contact__item {
    padding: 1.25rem;
  }

  .club-contact__icon {
    font-size: 1.25rem;
  }

  .club-contact__text h3 {
    font-size: 1rem;
  }

  .club-map__title h2 {
    font-size: 1.5rem;
  }

  .club-map__address {
    padding: 1rem;
  }

  .club-map__address-content h3 {
    font-size: 1.1rem;
  }

  .club-map__container {
    height: 350px;
  }
}

@media (max-width: 576px) {
  .club-contact__title,
  .club-info__title {
    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
  }

  .club-contact__title::after,
  .club-info__title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
    width: 60px;
    height: 3px;
    background: var(--tennis-ball-green);
    border-radius: 3px;
    transform: none;
  }

  .club-contact__title {
    font-size: 1.75rem;
  }

  .club-contact__item {
    padding: 1rem;
    gap: 1rem;
  }

  .club-map__title h2 {
    font-size: 1.25rem;
  }

  .club-map__address {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .club-map__container {
    height: 300px;
  }
}
/* Club Info Styles */
.club-info {
  padding: 5rem 0;
  background-color: var(--white);
}

.club-info__wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  position: relative;
  align-items: flex-start; /* Kartların üst hizalarını aynı seviyede tutmak için */
}

.club-info__main {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.club-info__section {
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
  padding: 2.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.club-info__section:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.08);
}

.club-info__about {
  height: fit-content;
}

.club-info__title {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: var(--black);
  position: relative;
  padding-bottom: 1rem;
}

.club-info__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: var(--tennis-ball-green);
  border-radius: 3px;
}

.club-info__icon {
  color: var(--tennis-ball-green);
  font-size: 1.5rem;
}

.club-info__description {
  color: var(--black);
  line-height: 1.8;
  font-size: 1.05rem;
  width: 100%;
  overflow: hidden;
}

.club-info__description p {
  margin-bottom: 1rem;
}

.club-info__description p:last-child {
  margin-bottom: 0;
}

/* Collapse Düğmesi Stillerı */
.club-info__expand-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  background-color: rgba(191, 215, 58, 0.1);
  color: var(--tennis-ball-dark-green);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: auto;
}

.club-info__expand-button:hover {
  background-color: rgba(191, 215, 58, 0.2);
}

.club-info__expand-icon {
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.club-info__about.expanded .club-info__description {
  /* Expanded durumunda herhangi bir max-height sınırlaması olmayacak, component içinde dynamik olarak ayarlanıyor */
}

/* Features Styles */
.club-info__features-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.25rem;
}

.club-info__feature-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: rgba(191, 215, 58, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.club-info__feature-item:hover {
  background: rgba(191, 215, 58, 0.2);
  transform: translateX(5px);
}

.club-info__feature-icon {
  color: var(--tennis-ball-dark-green);
  font-size: 1.2rem;
  flex-shrink: 0;
}

/* Contact Styles */
.club-info__contact {
  height: fit-content;
  position: sticky;
  top: 2rem;
  align-self: flex-start; /* Sabit pozisyonda kalması için */
}

.club-info__contact-details {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
  flex: 1;
}

.club-info__contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(191, 215, 58, 0.2);
}

.club-info__contact-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.club-info__contact-icon {
  color: var(--tennis-ball-green);
  font-size: 1.2rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.club-info__contact-text {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.club-info__contact-text strong {
  color: var(--black);
  font-weight: 600;
}

.club-info__contact-text p {
  color: var(--black);
}

.club-info__contact-text a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.club-info__contact-text a:hover {
  color: #333;
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .club-info__wrapper {
    gap: 2rem;
  }

  .club-info__section {
    padding: 2rem;
  }
}

@media (max-width: 992px) {
  .club-info__wrapper {
    grid-template-columns: 1fr;
  }

  .club-info__contact {
    position: static;
  }

  .club-info__description {
    max-height: none !important; /* Mobil görünümde collapse kaldırılıyor */
    overflow: visible !important;
  }

  .club-info__expand-button {
    display: none !important; /* Mobil görünümde butonu gizle */
  }
}

@media (max-width: 768px) {
  .club-info {
    padding: 3rem 0;
  }

  .container {
    padding: 0 1.5rem;
  }

  .club-info__main {
    gap: 2rem;
  }

  .club-info__title {
    font-size: 1.5rem;
  }

  .club-info__description {
    font-size: 1rem;
  }

  .club-info__features-list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 576px) {
  .club-info {
    padding: 2.5rem 0;
  }

  .club-info__section {
    padding: 1.5rem;
    border-radius: 12px;
  }

  .club-info__title {
    font-size: 1.35rem;
    margin-bottom: 1.25rem;
  }

  .club-info__features-list {
    grid-template-columns: 1fr;
  }

  .club-info__contact-item {
    padding-bottom: 1rem;
  }
}

/* Club Reviews Styles */
.club-reviews {
  padding: 5rem 0;
  background-color: #f9f9fb;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2rem;
}

.club-reviews__content {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.club-reviews__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.club-reviews__title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #262a41;
  margin: 0;
  position: relative;
}

.club-reviews__title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #ff8c42, #ff5252);
  border-radius: 3px;
}

.club-reviews__google-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #4285f4;
  color: white;
  padding: 8px 15px;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(66, 133, 244, 0.3);
}

.club-reviews__summary {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin-top: 1rem;
}

.club-reviews__rating {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.club-reviews__average {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.club-reviews__number {
  font-size: 3rem;
  font-weight: 800;
  color: #262a41;
  line-height: 1;
}

.club-reviews__stars {
  display: flex;
  gap: 5px;
}

.review-star {
  font-size: 1.25rem;
  color: #d1d5db;
}

.review-star.filled {
  color: #ffb800;
}

.review-star.half-filled {
  color: #ffb800;
}

.club-reviews__total {
  font-size: 1.1rem;
  color: #6b7280;
  font-weight: 500;
  margin: 0;
}

.club-reviews__list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.club-reviews__item {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.03);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.club-reviews__item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.07);
}

.club-reviews__item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.club-reviews__user {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.club-reviews__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #f0f0f0;
}

.club-reviews__user-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.club-reviews__user-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #262a41;
  margin: 0;
}

.club-reviews__item-stars {
  display: flex;
  gap: 3px;
}

.club-reviews__item-stars .review-star {
  font-size: 1rem;
}

.club-reviews__date {
  font-size: 0.9rem;
  color: #9ca3af;
}

.club-reviews__comment {
  color: #4b5563;
  line-height: 1.7;
  font-size: 1rem;
}

.club-reviews__comment p {
  margin: 0;
}

.club-reviews__empty {
  text-align: center;
  padding: 3rem 0;
  color: #6b7280;
  font-size: 1.1rem;
}

/* Loading */
.club-reviews__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  gap: 1.5rem;
}

.club-reviews__loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #4285f4;
  animation: spin 1s linear infinite;
}

.club-reviews__error {
  background: rgba(239, 68, 68, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  border-left: 4px solid #ef4444;
  color: #b91c1c;
  text-align: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .club-reviews {
    padding: 4rem 0;
  }

  .club-reviews__title {
    font-size: 2.2rem;
  }

  .club-reviews__rating {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .club-reviews {
    padding: 3rem 0;
  }

  .club-reviews__title {
    font-size: 2rem;
  }

  .club-reviews__content {
    gap: 2rem;
  }

  .club-reviews__item {
    padding: 1.5rem;
  }

  .club-reviews__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .club-reviews__item-header {
    flex-direction: column;
    gap: 1rem;
  }

  .club-reviews__date {
    align-self: flex-start;
  }
}

@media (max-width: 576px) {
  .club-reviews {
    padding: 2.5rem 0;
  }

  .container {
    padding: 0 1rem;
  }

  .club-reviews__title {
    font-size: 1.8rem;
  }

  .club-reviews__number {
    font-size: 2.5rem;
  }

  .club-reviews__summary {
    padding: 1.5rem;
  }

  .club-reviews__item {
    padding: 1.25rem;
  }

  .club-reviews__user {
    gap: 0.75rem;
  }

  .club-reviews__avatar {
    width: 40px;
    height: 40px;
  }
}
